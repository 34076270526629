<template>
  <div class="content capital-journal-list">
    <div class="form-content">
      <a-row :gutter="16">
        <a-col :span="6">
          <CommonSelect
            :isBu="true"
            :list="$store.state.settlementBuList"
            :placeholder="$t('结算组织')"
            :code.sync="formData.businessUnitCode"
            @change="searchHandle(1)"
          />
        </a-col>
        <a-col :span="6">
          <a-input
            :placeholder="$t('资金账户')"
            :value="formData.capitalAccountName"
            readOnly
            @click="showAccountModal"
          />
        </a-col>
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.referDocCode"
            :placeholder="$t('请输入关联单据编码')"
          />
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            allowClear
            :needhms="false"
            @get_date="chooseAccountingDate"
            :placeholder="$t(`记账日期`)"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt10">
        <a-col :span="6">
          <DateRangePicker
            allowClear
            :needhms="false"
            @get_date="chooseBizDate"
            :placeholder="$t(`业务日期`)"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      ref="CommonTableRef"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :showSelect="true"
      :scroll="{ x: 1500 }"
      :selectedRowKeys.sync="selectedRowKeys"
      :selectedRows.sync="selectedRows"
      :total="total"
      rowKey="id"
      @select="onSelectChange"
      @search="getList"
    >
      <template v-slot:referDocCode="{ text, record }">
        <a @click="goJump(record)">{{ text }}</a>
      </template>
    </CommonTable>

    <SelectCapitalAccountModal
      ref="CapitalAccountModal"
      :businessUnitCode="formData.businessUnitCode"
      @ok="handleAssetCardOk"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import DateRangePicker from '@/component/dateRangePicker'
import SelectCapitalAccountModal from '@component/selectCapitalAccountModal'
import { convertKeysToCamelCase, jumpSoucePage } from '@/common'
export default {
  name: 'CapitalJournalList',
  components: { DateRangePicker, SelectCapitalAccountModal },
  data() {
    return {
      docType: 'CapitalJournal',
      formData: {
        capitalAccountIdList: [],
        capitalAccountName: '',
      },
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      total: 0,
      loading: false,

      columns: [],
    }
  },
  mounted() {
    this.setColumns()
  },
  activated() {
    this.searchHandle()
  },
  computed: {},
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.setColumns()
      this.searchHandle(1)
    },
    setColumns() {
      this.columns = [
        {
          title: this.$t('银行账号'),
          dataIndex: 'capitalAccountCode',
          width: 200,
        },
        {
          title: this.$t('账户名称'),
          dataIndex: 'capitalAccountName',
        },
        // {
        //   title: this.$t('币别'),
        //   dataIndex: 'currency',
        // },
        {
          title: this.$t('记账日期'),
          dataIndex: 'accountingDate',
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'businessUnitName',
        },
        // {
        //   title: this.$t('摘要'),
        //   dataIndex: 'summary',
        // },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'referDocType',
          customRender: (text) => this.$store.getters.documentTypeMapping[text],
        },
        {
          title: this.$t('单据号'),
          dataIndex: 'referDocCode',
          width: 200,
          scopedSlots: { customRender: 'referDocCode' },
        },
        {
          title: this.$t('凭证字号'),
          dataIndex: 'voucherNo',
        },
        {
          title: this.$t('支付方式'),
          dataIndex: 'settlementMethodName',
        },
        {
          title: this.$t('借方金额'),
          dataIndex: 'debitAmount',
          customRender: (text, record) =>
            record.direction === 'DEBIT' && this.$currencySymbol(record.amount, record.currency),
        },
        {
          title: this.$t('贷方金额'),
          dataIndex: 'creditAmount',
          customRender: (text, record) =>
            record.direction === 'CREDIT' && this.$currencySymbol(record.amount, record.currency),
        },
        {
          title: this.$t('余额方向'),
          dataIndex: 'itemDirection',
          customRender: (text, record) =>
            record.itemBalance > 0
              ? this.$t('借')
              : record.itemBalance < 0
              ? this.$t('贷')
              : this.$t('平'),
        },
        {
          title: this.$t('余额'),
          dataIndex: 'itemBalance',
          customRender: (text, record) => this.$currencySymbol(text, record.currency),
          width: 150,
        },
      ]
    },
    searchHandle(pageNo) {
      if (!this.formData.businessUnitCode) return
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getCapitalJournalList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    chooseAccountingDate(date) {
      this.formData.accountingDateStart = date.start_time
      this.formData.accountingDateEnd = date.end_time
    },
    chooseBizDate(date) {
      this.formData.bizDateStart = date.start_time
      this.formData.bizDateEnd = date.end_time
    },
    goJump(record) {
      jumpSoucePage(record.referDocCode, record.referDocId)
    },
    showAccountModal() {
      this.$refs.CapitalAccountModal.open()
    },
    handleAssetCardOk(list) {
      this.formData.capitalAccountIdList = list.map((item) => item.id)
      this.formData.capitalAccountName = list.map((item) => item.name).join(';')
      this.$refs.CapitalAccountModal.close()
    },
  },
}
</script>
